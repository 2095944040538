import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          <code>Visionoid |</code>
          <code style={{fontSize:15}}> Imaginations Visualized</code>
        </p>
        
      </header>
      <div>Release 0.0.1</div>
    </div>
  );
}

export default App;
